import React, { useRef, useState } from 'react';

import {
    container,
    imageBox,
    ratio,
    header,
    nameText,
    positionText,
    doodle,
    doodle01,
    doodle02,
    doodle03,
    doodle04,
} from './player-card.module.scss';
import { IPlayer } from '../../models/player.model';
import { TAccentColor } from '../../models/accent-color.model';
import { getArrayInRandomSequence } from '../../utils/get-array-in-random-sequence';

import Doodle, { IDoodleProps } from '../atoms/doodle';
import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';

interface IPlayerCardProps {
    className?: string;
    NameTag?: React.ElementType;
    player: IPlayer;
}

const PlayerCard: React.FC<IPlayerCardProps> = ({ className = '', player, NameTag = 'h2' }) => {
    const { media, name, position } = player;
    const colorsRef = useRef(getArrayInRandomSequence(colors));
    const [makeSplash, setMakeSplash] = useState(false);

    const handleMouseEnter = () => {
        setMakeSplash(true);
    };

    const handleSplashEnd = () => {
        setMakeSplash(false);
    };

    return (
        <div className={`${container} ${className}`} onMouseEnter={handleMouseEnter}>
            <div className={imageBox}>
                <ImageWithErrorPlaceholder media={media} ratioClass={ratio} />
                {doodles.map((doodle, index) => {
                    return (
                        <Doodle
                            key={`doodle-${index}`}
                            {...doodle}
                            {...(index === 0 ? { onSplashEnd: handleSplashEnd } : {})}
                            makeSplash={makeSplash}
                            color={colorsRef.current[index]}
                        />
                    );
                })}
            </div>
            <div className={header}>
                <NameTag className={nameText}>{name}</NameTag>
                <p className={positionText}>{position}</p>
            </div>
        </div>
    );
};

const doodles: IDoodleProps[] = [
    { className: `${doodle} ${doodle01}` },
    { className: `${doodle} ${doodle02}` },
    { className: `${doodle} ${doodle03}` },
    { className: `${doodle} ${doodle04}` },
];

const colors: TAccentColor[] = ['purple', 'blue', 'yellow', 'pink'];

export default PlayerCard;
