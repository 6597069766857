import React from 'react';

import { container, ratio } from './client-card.module.scss';
import { IClient } from '../../models/client.model';

import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';

interface IClientCardProps {
    className?: string;
    client: IClient;
}

const ClientCard: React.FC<IClientCardProps> = ({ className = '', client }) => {
    const { media, url, label } = client;

    const Tag = url ? 'a' : 'div';
    const tagProps = url
        ? {
              href: url,
              rel: 'noopener noreferrer',
              target: '_blank',
          }
        : {};

    return (
        <Tag className={`${container} ${className}`} title={label} {...tagProps}>
            <ImageWithErrorPlaceholder media={media} ratioClass={ratio} objectFit="scale-down" />
        </Tag>
    );
};

export default ClientCard;
